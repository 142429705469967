import React from "react"
import Layout from "../../components/Layout"
import { PageHeading } from "../../components/PageHeading"
import { Link } from "gatsby"
import CallToActionPageBottom from "../../components/CallToActionPageBottom"
import tw from "twin.macro"

import security from "../../assets/undraw_security_o890.svg"
import collaboration from "../../assets/undraw_Collaboration_re_vyau.svg"
import userWizard from "../../assets/undraw_Setup_wizard_re_nday.svg"
import { Fade } from "react-reveal"

const H2 = tw.h2`pt-4 pb-2 text-4xl font-black bg-clip-text text-transparent bg-gradient-to-r to-indigo-400 from-indigo-800`
const UL = tw.ul`list-disc list-inside text-base text-gray-800 leading-8 max-w-prose mx-auto`
const P = tw.p`max-w-prose py-2 text-gray-600 leading-8`

export default function ForEnterprise() {
  return (
    <>
      <Layout title="Enterprise Features | Pricelist">
        <div class="relative px-4 sm:px-6 lg:px-8 mt-16">
          <div class="text-lg max-w-4xl mx-auto">
            <PageHeading
              title="Enterprise Features"
              subtitle="Learn how to integrate Pricelist into your enterprise setup"
            />

            <div className="mt-6 prose prose-indigo prose-base sm:prose-lg text-gray-500 mx-auto">
              <p></p>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between align-center mb-16 mt-16">
              <div className="md:w-2/3 w-full mr-8">
                <H2>User Management</H2>

                <P>Managing your employees in Pricelist granular yet simple.</P>

                <UL>
                  <li>Single Sign-On with SAML authentication</li>
                  <li>Role-based access to features</li>
                  <li>Multi-factor authentication</li>
                </UL>
              </div>
              <div className="w-1/3 flex">
                <Fade>
                  <img
                    src={userWizard}
                    alt="user management"
                    className="mx-auto my-auto"
                  />
                </Fade>
              </div>
            </div>

            <div className="flex flex-col md:flex-row-reverse md:justify-between align-center mb-16 mt-16">
              <div className="md:w-2/3 w-full ml-8">
                <H2>Collaboration</H2>

                <P>
                  Pricelist gives your employees new ways to collaborate in the
                  pricing and go-to-market processes.
                </P>

                <UL>
                  <li>Approvals engine</li>
                  <li>Single source of truth</li>
                  <li>Decisions audit trail</li>
                </UL>
              </div>
              <div className="w-1/3 flex">
                <Fade>
                  <img
                    src={collaboration}
                    alt="collaboration"
                    className="mx-auto my-auto"
                  />
                </Fade>
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between align-center mb-16 mt-16">
              <div className="md:w-2/3 w-full mr-8">
                <H2>Security</H2>
                <P>Protecting your data is our top priority.</P>

                <UL>
                  <li>
                    Multi-tenant architecture enforced at the infrastructure
                    layer
                  </li>
                  <li>Data deletion, data export, and encryption at rest</li>
                  <li>Top vendor security with SOC2 audits</li>
                  <li>
                    Learn more about Pricelist's{" "}
                    <Link
                      to="/security"
                      className="underline hover:no-underline text-indigo-700"
                    >
                      security
                    </Link>
                  </li>
                </UL>
              </div>
              <div className="w-1/3 flex">
                <Fade>
                  <img
                    src={security}
                    alt="security"
                    className="mx-auto my-auto"
                  />
                </Fade>
              </div>
            </div>
          </div>
          <CallToActionPageBottom cta="Get in touch">
            <div>Learn how Pricelist fits into your enterprise</div>
            <div>Reach out to request more resources</div>
          </CallToActionPageBottom>
        </div>
      </Layout>
    </>
  )
}

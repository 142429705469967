import React from "react"

export const PageHeading = ({ title, subtitle }) => {
  return (
    <h1>
      <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">
        {subtitle}
      </span>
      <span class="my-4 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        {title}
      </span>
    </h1>
  )
}

import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-reveal"

export const CallToActionPageBottom = ({
  children,
  cta = "Schedule a demo",
  link = "/contact-sales",
}) => {
  return (
    <Fade duration={3000}>
      <div className="w-full sm:w-2/3 mx-auto rounded-lg shadow-sm my-32 bg-gray-50">
        <div className="flex justify-center items-center flex-col p-10 text-center">
          <h3 className="text-lg sm:text-4xl text-gray-700 pb-4 tracking-wide">
            {(children && children[0]) ||
              "Go to market faster with less guesswork"}
          </h3>
          <span className="text-sm sm:text-lg text-gray-500 pb-4">
            {(children && children[1]) ||
              "Learn how Pricelist can help your company."}
          </span>

          <Link
            to={link}
            className="whitespace-nowrap px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            {cta}
          </Link>
        </div>
      </div>
    </Fade>
  )
}

export default CallToActionPageBottom
